@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    height: 100%;
    margin: 0;
}

* {
    font-family: 'Encode Sans', sans-serif !important;
}

a {
    &:link,
    &:visited,
    &:hover,
    &:active {
        color: #4287f5;
        text-decoration: none;
    }
}
