@use "@angular/material" as mat;

@include mat.core();
@import "globals.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$app-primary: mat.define-palette(mat.$blue-palette);
$app-accent: mat.define-palette(mat.$purple-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$app-theme: mat.define-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

// Define a light theme
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

@media (prefers-color-scheme: light) {
  @include mat.all-component-colors($light-theme);
}
